.inputContainer{
    display: flex;
    flex-direction: column;
    .selectorContainer{
      width: 100%;
      .ant-select-selector{
        height: 54px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        align-items: center;
        &:hover{
          border-color: #40a9ff;
        }
    
      }
    }
  }