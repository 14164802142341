* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

$mainColor: #1a76d2;
$secondaryColor: #ccc;

.invoicing-page {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .ant-table-thead>tr>th {
    font-weight: 700;
  }

  .action-icon {
    font-size: 22px;
    cursor: pointer;
  }

  .inner {
    max-width: 980px;
    margin: 0 auto;
    padding: 50px 0;

    .title {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding: 0 0 20px 0;
      font-weight: bold;

      .pageTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          padding-left: 10px;
        }

        svg {
          color: $mainColor;
          font-size: 30px;
        }
      }

      .main-button {
        text-transform: uppercase;
        height: 50px;
        font-size: 20px;
      }
    }

    .row_flagged {
      background-color: #fff7e6;
    }

    .row_tobeInvoice {
      background-color: #e6f7ff;
    }

    .row_invoiced {
      color: #8c8c8c;
      // pointer-events: none;
    }
  }
}

.invoicingMoreActionToast {
  max-width: 250px;

  .toastTitle {
    display: flex;
    justify-content: space-between;

    .titleText {
      color: #1890ff;
      font-size: 20px;
    }

    .titleRemoveIcon {
      text-align: center;
      font-size: 20px;
    }

    .titleRemoveIcon:hover {
      cursor: pointer;
    }
  }

  .toastContent {
    display: flex;
    flex-direction: column;
  }

  .checkboxList {
    padding: 5px 0px 10px 0px;
  }
}

.titleModal {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h2 {
    padding-left: 10px;
    margin: auto 0px;
  }

  svg {
    color: $mainColor;
    font-size: 30px;
  }
}

.dataPicker {
  border: 1px solid $secondaryColor;
  border-radius: 5px;
  height: 55px;
  width: 150px;
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;

  .label {
    left: 15px;
    top: -12px;
    font-size: 13px;
    background: #fff;
    padding: 0 5px;
    position: absolute;
  }

  input {
    border: none;
    font-size: 16px;
  }

  .ant-input-group-addon {
    .ant-btn {
      border: none;
      box-shadow: none;
    }
  }

  .ant-picker {
    border: none;
    width: 100%;
    font-size: 24px;
  }

  .ant-select {
    width: 100%;
    min-width: 170px;

    .ant-select-selector {
      border: none;
    }
  }

  &.status {
    min-width: 180px;
    justify-self: right;
  }
}

.toastContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .stateChangeFlow {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .stateItem {
      margin: 0px 4px;
      padding: 2px 5px;
      border: 1px solid;
      font-size: 12px;
    }
  }
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
}

.invoiceStatusContainer {
  margin-top: 10px;

  .processContainer {
    display: flex;

    .statusItem {
      flex-grow: 1;
      display: flex;

      .statusItemArrow {
        flex-grow: 1;
        margin: 0px 15px;
      }
    }

    .middleItem {
      flex-grow: 1;
    }

    .lastItem {
      text-align: right;
    }
  }
}

.priceEditContainer {
  display: flex;

  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-right: 19px;
  }

  .priceContainer {
    text-align: right;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.productEditContainer {
  display: flex;

  .inputColum {
    flex-grow: 1;
    margin-left: 19px;
  }
}

.priceCalculationSpinner {
  text-align: center;
}

.inputTextField {
  height: 54px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 15px 12px;

  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    border-right-width: 1px;
  }

  &:focus-visible {
    outline: unset;
  }

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px;
  }
}