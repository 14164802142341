.editCustomerContainer{
    display:flex;
    .orderCustomerContainer{
        flex-grow: 1;
        flex-basis: 0;
    }
    .invoiceCustomerContainer{
        flex-grow: 1;
        flex-basis: 0;
        padding:0px 10px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        box-sizing: border-box;
        .header{
            display: flex;
            justify-content: space-between;
        }
        .content{
            margin-top: 10px;
            color:#1976D2
        }
    }
}
