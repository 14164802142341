.inputContainer {
  display: flex;
  flex-direction: column;
  .inputNumberContainer {
    border: unset;
    width: 100%;
    .ant-input-number-input {
      height: 54px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      padding: 15px 12px;
      &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
      }
      &:focus-visible {
        outline: unset;
      }
      &:hover {
        border-color: #40a9ff;
      }
    }
  }
}
